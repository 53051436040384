@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;  
}


@font-face {
  font-family: "Montserrat-ExtraBold";
  src: local("Montserrat-ExtraBold"),
    url("/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: normal;  
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;  
}



@font-face {
  font-family: "Dosis-Bold";
  src: local("Dosis-Bold"),
    url("/fonts/Dosis-Bold.ttf") format("truetype");
  font-weight: normal;  
}

@font-face {
  font-family: "Dosis-ExtraBold";
  src: local("Dosis-ExtraBold"),
    url("/fonts/Dosis-ExtraBold.ttf") format("truetype");
  font-weight: normal;  
}

@font-face {
  font-family: "Dosis-SemiBold";
  src: local("Dosis-SemiBold"),
    url("/fonts/Dosis-SemiBold.ttf") format("truetype");
  font-weight: normal;  
}






